<template>
  <div class="treasury-payment-gateways-list fixed-draggable-dynamic-table-wrapper-height">
    <draggable-dynamic-table ref="paymentGatewaysList"
                             :data="data"
                             :columns="columnsLabel"
                             :options="options"
                             @filter:remove="setFilter($event, true)"
                             @filter:set="setFilter($event)"
                             @sort:set="setSort($event)"
                             @load:more="getPaymentGateways()"
                             @product:restore="restorePaymentGateway($event)"
                             @product:deleted="$refs.deleteConfirmation.showDialog(), paymentGatewayDataForDelete = $event"/>

    <custom-dialog ref="deleteConfirmation"
                   :title="`${$t('treasury.paymentGateways.confirmations.delete.title')}`"
                   :body="$t('treasury.paymentGateways.confirmations.delete.body', {paymentGateway: paymentGatewayDataForDelete.title})"
                   @accept="deletePaymentGateway(paymentGatewayDataForDelete)"/>
    </div>
</template>

<script>
import axios from 'axios'
import {
  deletePaymentGateways,
  getPaymentGateways,
  restorePaymentGateways
} from '../../../../../http/requests/treasury/paymentGateways'
import {getBanks} from '../../../../../http/requests/treasury/banks'
import CustomDialog from '../../../../../components/customDialog/customDialog'
import {checkUserPermissions} from "../../../../../assets/js/functions";

export default {
  name: 'trashedPaymentGateways',
  components: {CustomDialog},
  metaInfo () {
    return {
      title: this.$t('treasury.paymentGateways.trash.title')
    }
  },
  data () {
    return {
      showPaymentGatewayProfilePromptStatus: false,
      editPaymentGatewayPromptStatus: false,
      loadingTimer: 0,
      options: {
        id: 'paymentGatewaysList',
        rowKeyField: 'id'
      },
      columnsLabel: [
        {
          field: 'storeAction',
          i18n: 'treasury.paymentGateways.table.header.restore',
          color: 'success',
          width: '70px',
          minWidth: 70,
          locked: true,
          actions: true,
          showAction: 'always',
          event: 'product:restore',
          action: {
            icon: 'REFRESH',
            iconPack: 'useral',
            color: 'success',
            type: 'button'
          },
          classes: 'mx-auto'
        },
        {
          field: 'deleteAction',
          i18n: 'treasury.paymentGateways.table.header.delete',
          color: 'danger',
          width: '70px',
          minWidth: 70,
          align: 'center',
          locked: true,
          actions: true,
          showAction: 'always',
          event: 'product:deleted',
          action: {
            icon: 'TRASH',
            iconPack: 'useral',
            color: 'danger',
            type: 'button'
          },
          classes: 'mx-auto'
        },
        {
          field: 'balance',
          i18n: 'treasury.paymentGateways.table.header.balance',
          width: 'calc(100% / 4)',
          minWidth: 130,
          sortable: true,
          filter: true,
          filterTypes: [
            {
              icon: 'equals',
              name: 'برابر باشد',
              i18n: 'draggableTable.filter.types.equals',
              id: 1
            },
            {
              icon: 'not-equal',
              name: 'برابر نباشد',
              i18n: 'draggableTable.filter.types.notEqual',
              id: 2
            },
            {
              icon: 'greater-than-equal',
              name: 'بزرگتر مساوی',
              i18n:
                'draggableTable.filter.types.greaterThanEquals',
              id: 3
            },
            {
              icon: 'less-than-equal',
              name: 'کوچکتر مساوی',
              i18n: 'draggableTable.filter.types.lessThanEquals',
              id: 4
            }
          ],
          footer: {
            type: 'auto-sum',
            textType: 'price'
          }
        },
        // {
        //   field: 'bank',
        //   i18n: 'treasury.paymentGateways.table.header.bank',
        //   width: 'calc(100% / 4)',
        //   minWidth: 170,
        //   filter: true,
        //   filterType: 'checkbox',
        //   filterTypes: [],
        //   align: 'center',
        //   sortable: true
        // },
        {
          field: 'status',
          i18n: 'treasury.paymentGateways.table.header.status',
          width: 'calc(100% / 4)',
          minWidth: 170,
          filter: true,
          filterType: 'select',
          filterTypes: [
            {
              label: this.$t('treasury.paymentGateways.status.all'),
              value: '-1'
            },
            {
              label: this.$t('treasury.paymentGateways.status.enable'),
              value: '1'
            },
            {
              label: this.$t('treasury.paymentGateways.status.disable'),
              value: '0'
            }
          ],
          align: 'center',
          sortable: true
        },
        {
          field: 'title',
          i18n: 'treasury.paymentGateways.table.header.title',
          width: 'calc(100% / 4)',
          minWidth: 170,
          filter: true,
          filterTypes: [
            {
              icon: 'search',
              name: 'شامل شود',
              i18n: 'draggableTable.filter.types.search',
              id: 1
            }
          ],
          align: 'center',
          sortable: true
        },
        {
          field: 'type',
          i18n: 'treasury.paymentGateways.table.header.type',
          width: 'calc(100% / 4)',
          minWidth: 170,
          align: 'center',
          filter: true,
          filterType: 'select',
          filterTypes: [
            {
              label: this.$t('treasury.paymentGateways.types.all'),
              value: '-1'
            },
            {
              label: this.$t('treasury.paymentGateways.types.online'),
              value: '1'
            },
            {
              label: this.$t('treasury.paymentGateways.types.pos'),
              value: '2'
            }
          ],
          sortable: true,
          locked: true
        },
        {
          field: 'rowNumber',
          i18n: 'treasury.paymentGateways.table.header.row',
          width: '60px',
          minWidth: 60,
          align: 'center',
          // sortable: true,
          locked: true,
          footer: {}
        }
      ],
      data: [],
      paymentGatewayDataForDelete: {},
      filters: ['trashed=true'],
      sorts: [],
      page: 1,
      endedList: false,
      actions: [
        {
          toolbar: [],
          leftToolbar: [
            {
              id: 'printTable',
              // i18n: 'draggableDynamicTable.actions.print',
              icon: 'PRINT',
              iconPack: 'useral'
            },
            {
              id: 'downloadTable',
              // i18n: 'draggableDynamicTable.actions.download',
              icon: 'DOWNLOAD',
              iconPack: 'useral'
            },
            {
              id: {name: 'paymentGateways'},
              type: 'link',
              icon: 'CHEVRON_LEFT',
              iconPack: 'useral'
            }
          ]
        }
      ]
    }
  },
  created () {
    setTimeout(() => {
      this.$store.dispatch('updateNavbarActions', this.actions[0])
      this.$store.dispatch('updateContentNavbarStyle', 'sticky')
    }, 50)

    if (!checkUserPermissions('payment_gateway.forceDelete')) {
      for (let i = 0; i < this.columnsLabel.length; i++) {
        if (this.columnsLabel[i].field === 'deleteAction') {
          this.columnsLabel.splice(i, 1)
        }
      }
    }

    if (!checkUserPermissions('payment_gateway.restore')) {
      for (let i = 0; i < this.columnsLabel.length; i++) {
        if (this.columnsLabel[i].field === 'storeAction') {
          this.columnsLabel.splice(i, 1)
        }
      }
    }

    this.getPaymentGateways()
    // this.getBanks()
  },
  methods: {
    getPaymentGateways () {
        clearTimeout(this.loadingTimer)
        this.loadingTimer = setTimeout(() => {
          if (!this.endedList) {
            if (this.$refs.paymentGatewaysList && this.data.length === 0) this.$refs.paymentGatewaysList.loadMoreStatus = 'FirstLoad'
            else if (this.$refs.paymentGatewaysList && this.data.length > 0) this.$refs.paymentGatewaysList.loadMoreStatus = 'Loading'

            getPaymentGateways(this.page, this.filters, this.sorts).then(response => {
              this.endedList = response.data.data.length === 0
              const paymentGateways = response.data.data

              paymentGateways.forEach((paymentGateway) => {
                this.data.push({
                  /*route: {
                    name: 'paymentGateway',
                    params: {id: paymentGateway.id}
                  },*/
                  id: paymentGateway.id,
                  rowNumber: this.data.length + 1,
                  title: paymentGateway.title,
                  // bank: paymentGateway.bank ? paymentGateway.bank.name : '-',
                  type: paymentGateway.type === 1 ? this.$t('treasury.paymentGateways.types.online') : this.$t('treasury.paymentGateways.types.pos'),
                  status: paymentGateway.enabled ? {value: this.$t('treasury.paymentGateways.status.enable'), classes: 'text-success'} : {value: this.$t('treasury.paymentGateways.status.disable'), classes: 'text-danger'},
                  balance: {
                    type: 'price',
                    classes: paymentGateway.balance && paymentGateway.balance < 0 ? 'text-danger' : 'text-success',
                    value: paymentGateway.balance ? paymentGateway.balance : 0
                  }
                })
              })

              this.page = response.data.pagination.current_page + 1
              if (response.data.pagination.current_page === 1) {
                const row_index = this.columnsLabel.map((e) => {
                  return e.field
                }).indexOf('rowNumber')
                this.columnsLabel[row_index].footer.value = response.data.pagination.total
              }

              if (this.$refs.paymentGatewaysList) this.$refs.paymentGatewaysList.loadMoreStatus = ''
            }).catch((error) => {

              if (this.$refs.paymentGatewaysList && !axios.isCancel(error)) this.$refs.paymentGatewaysList.loadMoreStatus = 'Danger'
            })
          }
        }, 400)
    },
    getBanks () {
      getBanks().then(response => {
        const banks = []
        response.data.data.forEach((bank) => {
          banks.push({
            id: bank.id,
            label: bank.name,
            value: bank.id,
            show: false
          })
        })

        this.columnsLabel[this.columnsLabel.map(elm => {
          return elm.field
        }).indexOf('bank')].filterTypes = banks
      })
    },
    setFilter (filters) {
      if (!this.getFiltersStatus) {
        const filters_list = []
        Object.keys(filters).forEach((key) => {
          switch (key) {

          case 'bank':
            if (filters[key].search.length) {
              const banks_id = filters[key].search.map(elm => {
                return elm.show ? elm.id : null
              }).filter(elm => {
                return elm !== null
              }).join('_')

              if (banks_id !== '') filters_list.push(`bank=${banks_id}`)
            }
            break

          case 'balance':
            if (filters[key].search !== '') filters_list.push(`balance=${filters[key].search}${filters[key].type.id > 0 ? (`,${filters[key].type.id}`) : ''}`)
            break

          case 'title':
            if (filters[key].search !== '') filters_list.push(`title=${filters[key].search}${filters[key].type.id > 0 ? (`,${filters[key].type.id}`) : ''}`)
            break

          case 'type':
            if (filters[key].search.value > -1) filters_list.push(`type=${filters[key].search.value}`)
            break

          case 'status':
            if (filters[key].search.value >= 0) filters_list.push(`enabled=${filters[key].search.value}`)
            break
          }
        })
        filters_list.push('trashed=true')

        this.data = []
        this.page = 1
        this.endedList = false
        this.filters = filters_list
        this.getPaymentGateways()
      }
    },
    setSort (sorts) {
      const sorts_list = []
      Object.keys(sorts).forEach((key) => {
        switch (key) {

        case 'rowNumber':
          sorts_list.push(`order[0]=id,${  sorts[key] ? 'desc' : 'asc'}`)
          break

        case 'bank':
          sorts_list.push(`order[0]=bank_id,${  sorts[key] ? 'desc' : 'asc'}`)
          break

        case 'balance':
          sorts_list.push(`order[0]=balance,${  sorts[key] ? 'desc' : 'asc'}`)
          break

        case 'title':
          sorts_list.push(`order[0]=title,${  sorts[key] ? 'desc' : 'asc'}`)
          break

        case 'type':
          sorts_list.push(`order[0]=type,${  sorts[key] ? 'desc' : 'asc'}`)
          break

        case 'status':
          sorts_list.push(`order[0]=enabled,${  sorts[key] ? 'desc' : 'asc'}`)
          break
        }
      })

      if (sorts_list.length === 0) {
        this.sorts.push('order[0]=updated_at,desc')
      }

      this.data = []
      this.page = 1
      this.endedList = false
      this.sorts = sorts_list
      this.getPaymentGateways()
    },
    handleClick (id) {
      document.getElementById(id).click()
    },
    restorePaymentGateway (paymentGateway) {
      restorePaymentGateways(paymentGateway.id).then(() => {
        this.$vs.notify({
          title: this.$t('alert.error.title'),
          text: this.$t('treasury.paymentGateways.notifications.restore.success'),
          color: 'success',
          time: 2400,
          icon: 'icon-check-circle',
          iconPack: 'feather'
        })

        this.data = []
        this.page = 1
        this.endedList = false
        this.getPaymentGateways()
      }).catch((error) => {
        if (axios.isCancel(error)) {
          this.$vs.notify({
            title: this.$t('alert.duplicateRequest.title'),
            text: this.$t('alert.duplicateRequest.message'),
            icon: 'icon-alert-circle',
            iconPack: 'feather',
            time: 2400,
            color: 'warning'
          })
        } else {
          this.$vs.notify({
            title: this.$t('alert.error.title'),
            text: this.$t('treasury.paymentGateways.notifications.restore.error'),
            color: 'danger',
            time: 2400,
            icon: 'icon-alert-circle',
            iconPack: 'feather'
          })
        }
      })
    },
    deletePaymentGateway (paymentGateway) {
      deletePaymentGateways(paymentGateway.id, true).then(() => {
        this.$vs.notify({
          title: this.$t('alert.message.title'),
          text: this.$t('treasury.paymentGateways.notifications.delete.success'),
          color: 'success',
          time: 2400,
          icon: 'icon-check-circle',
          iconPack: 'feather'
        })

        this.data = []
        this.page = 1
        this.endedList = false
        this.getPaymentGateways()
      }).catch((error) => {
        if (axios.isCancel(error)) {
          this.$vs.notify({
            title: this.$t('alert.duplicateRequest.title'),
            text: this.$t('alert.duplicateRequest.message'),
            icon: 'icon-alert-circle',
            iconPack: 'feather',
            time: 2400,
            color: 'warning'
          })
        } else {
          switch (error.response.status) {
            case 409:
              this.$vs.notify({
                title: this.$t('alert.error.title'),
                text: this.$t('treasury.paymentGateways.notifications.delete.isUsedError'),
                color: 'danger',
                icon: 'icon-alert-circle',
                iconPack: 'feather',
                time: 5000
              })
              break

            default:
              this.$vs.notify({
                title: this.$t('alert.error.title'),
                text: this.$t('treasury.paymentGateways.notifications.delete.error'),
                color: 'danger',
                time: 2400,
                icon: 'icon-alert-circle',
                iconPack: 'feather'
              })
              break
          }
        }
      })
    }
  }
}
</script>

<style scoped>

</style>
